import React from "react";
import Style from "./banner.module.scss";

import BannerImage from "assets/images/banner-image.png";
import GooglePlay from "assets/images/google-play.png";
import ArrowDown from "assets/icons/arrow-down.png";

import { Text } from "components/atoms/text";
import { Image } from "components/atoms/image";
import { Link } from "components/atoms/link";
import { handleScrollToSection } from "utils/utils";

interface IBannerProps {}

const Banner: React.FC<IBannerProps> = ({}) => {
  return (
    <div className={Style["banner"]} id="banner">
      <Image
        className={Style["background"]}
        src={BannerImage}
        alt="Banner Image"
      />
      <div className={Style["content"]}>
        <Text className={Style["title"]}>
          Your Pocket Arcade Adventure Awaits!
        </Text>
        <Text className={Style["description"]}>
          Experience an endless realm of entertainment and enjoyment at Digital
          Arcade, where the possibilities for fun are boundless.
        </Text>
        <div className={Style["button-container"]}>
          <Link
            href="https://play.google.com/store/apps/details?id=com.starcade.digitalarcade"
            target="_blank"
          >
            <Image
              className={Style["store-image"]}
              src={GooglePlay}
              alt="Google Play"
            />
          </Link>
        </div>
      </div>
      <div className={Style["scroll-down-container"]}>
        <Link href="/#about" onClick={handleScrollToSection}>
          <div className={Style["button-scroll"]}>
            <Image
              className={Style["icon"]}
              src={ArrowDown}
              alt="Scroll down image"
            />
            <Text className={Style["text"]}>Scroll Down</Text>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Banner;
