import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import Root from "routes/root";
import ErrorPage from "routes/404";
import { HomePage } from "routes/home";
import { TermsAndConditionsPage } from "./terms-conditions";
import { IOSBetaTestPage } from "./ios-beta-test";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "terms-conditions",
        element: <TermsAndConditionsPage />,
      },
      {
        path: "iosbetatest",
        element: <IOSBetaTestPage />,
      }
    ],
  },
]);

export default router;
