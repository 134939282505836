import React from "react";
import Style from "./contact-us.module.scss";

import { Text } from "components/atoms/text";
import { Button } from "components/atoms/button";

import ArrowRight from "assets/icons/arrow-right.png";
import { Image } from "components/atoms/image";

interface IContactUsProps {}

const ContactUs: React.FC<IContactUsProps> = ({}) => {
  const [name, setName] = React.useState<string>("");
  const [email, setEmail] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");

  const onClickSendMessage = React.useCallback(() => {
    const subject = `Contact Us Token Portal - ${name} - ${email}`;
    window.open(`mailto:hello@starcade.co?subject=${subject}&body=${message}`);
  }, [name, email, message]);

  return (
    <div className={Style["contact-us"]} id="contact-us">
      <div className={Style["content"]}>
        <div className={Style["top"]}>
          <div className={Style["left"]}>
            <Text className={Style["title"]}>Contact Us</Text>
            <Text className={Style["description"]}>
              If you have questions or just want to get in touch, use the form
              below. We look forward to hearing from you!
            </Text>
          </div>
          <div className={Style["right"]}>
            <div className={Style["name-email-container"]}>
              <input
                placeholder="Your Name"
                className={Style["form-name-email"]}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                placeholder="Your Email"
                className={Style["form-name-email"]}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <textarea
              placeholder="Message..."
              className={Style["form"]}
              draggable={false}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={Style["bottom"]}>
          <Button
            joinClassName={Style["button-container"]}
            childrenClassName={Style["button"]}
            parallelogram={false}
            variant="primary-round"
            onClick={onClickSendMessage}
          >
            <Text className={Style["button-text"]}>Send Message</Text>
            <Image
              src={ArrowRight}
              className={Style["button-icon"]}
              alt="arrow-right-icon"
            />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
