import React from "react";
import Style from "./bug-hunter-description.module.scss";

import BugHunterImage from "assets/images/bug-hunter.png";
import GiftIcon from "assets/icons/gift.png";
import {Button} from "../../../../components/atoms/button";
import {Text} from "../../../../components/atoms/text";
import {Image} from "../../../../components/atoms/image";
import ArrowRight from "../../../../assets/icons/arrow-right.png";

interface IBugHunterDescriptionProps {}

const BugHunterDescription: React.FC<IBugHunterDescriptionProps> = () => {

    const onClickJoinBetaTest = React.useCallback(() => {
        window.open("https://testflight.apple.com/join/uFPE1YwM");
    }, []);

    return (
        <div className={Style["bug-hunter-description"]} id="bug-hunter-description">
            <p className={Style["title"]}>Beri Masukan dan Bergabung sebagai Bug Hunter Season 1!</p>
            <div className={Style["box-content"]}>
                <div className={Style["upper"]}>
                    <div className={Style["content-container"]}>
                        <div className={Style["container"]}>
                            <p className={Style["content-text"]}>
                                Ketika melakukan uji coba, Anda dapat mengirimkan masukan kepada kami tentang masalah
                                yang Anda temui atau memberikan saran untuk perbaikan berdasarkan konten "Apa yang Harus
                                Diuji". Anda dapat memberikan masukan langsung melalui opsi di dalam aplikasi.
                            </p>
                        </div>
                        <div className={Style["container"]}>
                            <p className={Style["content-title"]}>
                                APA ITU BUG HUNTER?
                            </p>
                            <p className={Style["content-text"]}>
                                Bergabunglah dalam Bug Hunter Event dan berkesempatan memenang- kan uang tunai! Untuk 10 pengguna yang memberikan minimal 1 (SATU) laporan bug tervalidasi berkesempatan mendapatkan <span className={Style["bolded"]}>Rp500.000!</span>
                            </p>
                        </div>
                    </div>
                    <div className={Style["image-container"]}>
                        <img src={BugHunterImage} alt={"bug-hunter"}/>
                    </div>
                </div>
                <div className={Style["lower"]}>
                    <img src={GiftIcon} alt={"gift-icon"}/>
                    <p className={Style["text"]}>
                        Semakin banyak bug yang Anda laporkan, semakin besar peluang untuk menang!
                    </p>
                </div>
            </div>
            <Button
                joinClassName={Style["button-container"]}
                childrenClassName={Style["button"]}
                parallelogram={false}
                variant="primary-round"
                onClick={onClickJoinBetaTest}
            >
                <Text className={Style["button-text"]}>Ikuti Beta Test Disini</Text>
                <Image
                    src={ArrowRight}
                    className={Style["button-icon"]}
                    alt="arrow-right-icon"
                />
            </Button>
        </div>
    );
};

export default BugHunterDescription;
