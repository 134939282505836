import classNames from "classnames";
import React, { useMemo } from "react";
import DefaultImage from "assets/images/image-placeholder.png";
import Style from "./image.module.scss";

interface IImageProps {
  joinClassName?: string;
  alt?: string;
  className?: string;
  src?: string | ImageData;
}

const Image: React.FC<IImageProps> = (props) => {
  const { src, alt, className, joinClassName, ...otherProps } = props;

  const cn = useMemo(() => {
    if (joinClassName) {
      return classNames(Style["link"], joinClassName);
    }

    if (className) {
      return className;
    }

    return Style["link"];
  }, [joinClassName, className]);

  const imageSrc = useMemo(() => {
    if (!src) {
      return DefaultImage;
    }

    return src;
  }, [src]);

  const imageAlt = useMemo(() => {
    if (!alt) {
      return "luxavenue-picture";
    }

    return alt;
  }, [alt]);

  return <img src={imageSrc} alt={imageAlt} className={cn} {...otherProps} />;
};

export default Image;
