import React from "react";
import Style from "./bug-hunter-terms.module.scss";
import {joinClassNames} from "../../../../utils/utils";

import ChevronUpIcon from "assets/icons/chevron-up.png";
import DiamondIcon from "assets/icons/diamond.png";
import CheckCircleIcon from "assets/icons/check-circle.png";
import WarningYellowIcon from "assets/icons/warning-yellow.png";

interface IBugHunterTermsProps {}

const BugHunterTerms: React.FC<IBugHunterTermsProps> = () => {

    const [isAccordionOpen, setIsAccordionOpen] = React.useState(false);

    const onClickFBGroup = () => {
        window.open("https://www.facebook.com/groups/521001773482484", "_blank");
    }

    const onClickFBPage = () => {
        window.open("https://www.facebook.com/profile.php?id=61550932751720", "_blank");
    }

    const onClickInstagram = () => {
        window.open("https://www.instagram.com/starcadegame_id/", "_blank");
    }

    return (
        <div className={Style["bug-hunter-terms"]} id="bug-hunter-terms">
            <div className={Style["accordion"]}>
                <div className={Style["header"]} onClick={() => setIsAccordionOpen(!isAccordionOpen)}>
                    <p className={Style["text"]}>Syarat dan Ketentuan Bug Hunter</p>
                    <img src={ChevronUpIcon} alt="chevron-up" className={isAccordionOpen ? "" : Style["closed"]} />
                </div>
                <div className={joinClassNames(Style["content"], isAccordionOpen ? "" : Style["closed"])}>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            User harus bermain sebagai <span className={Style["italic"]}>real player</span> dan harus menemukan <span className={Style["italic"]}>bug</span> atau masalah yang bisa
                            mengganggu <span className={Style["italic"]}>user experience</span>.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Larangan Akses Sistem: Peserta <span className={Style["italic"]}>Bug Hunter</span> dilarang keras untuk mencoba masuk atau mengakses
                            area sistem yang tidak disediakan secara eksplisit untuk tujuan uji coba. Segala upaya yang
                            mencurigakan akan dianggap sebagai pelanggaran.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Tidak boleh mengubah, memodifikasi, atau mencoba merusak kode atau infrastruktur permainan
                            dengan sengaja atau melakukan segala bentuk tindakan yang dapat dianggap sebagai <span className={Style["italic"]}>hacking</span>.
                            Hanya pencarian <span className={Style["italic"]}>bug</span> dan memberikan laporan yang diizinkan dalam lingkup program ini.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            <span className={Style["italic"]}>User</span> harus mencoba semua game di dalam Starcade termasuk pada fitur lain, seperti fitur Pet,
                            dan penukaran tiket ke Lootbox atau Petbox.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Pertimbangkan aspek ini saat melakukan test game:
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            <span className={Style["italic"]}>User Flows</span>: Test berbagai <span className={Style["italic"]}>user journeys</span>.
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            Performa aplikasi: <span className={Style["italic"]}>Loading time, responsive game, dan lags</span>.
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            UI/UX: Visual, dan Layout dalam game.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Laporan Bug harus Komprehensif: Pastikan laporan <span className={Style["italic"]}>bug</span> mencakup penjelasan rinci tentang
                            masalah yang diidentifikasi.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Sertakan Tangkapan Layar atau Rekaman Video:
                            Lampirkan tangkapan layar atau rekaman video yang jelas menggambarkan bug dengan efektif.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Validasi oleh Tim QA Starcade:
                            Tim QA kami akan memvalidasi setiap laporan bug untuk memastikan keakuratannya.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Tidak diperbolehkan menyampaikan laporan dengan menghubungi karyawan Starcade secara
                            langsung atau melalui saluran lain termasuk pada akun resmi <span className={Style["italic"]}>social media</span> Starcade atau <span className={Style["italic"]}>email</span>
                            maupun telepon.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Hanya Bug yang dilaporkan melalui aplikasi TestFlight dan sudah tervalidasi oleh tim QA
                            Starcade yang akan dihitung!
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Validasi Kesesuaian dengan Aturan:
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            Laporan bug yang melibatkan upaya untuk mengakali sistem atau melanggar integritas permainan
                            akan dianalisis dengan cermat.
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            Peserta yang melanggar ketentuan ini dapat di diskualifikasi dari <span className={Style["italic"]}>Bug Hunter</span>.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Peserta diharapkan untuk berpartisipasi dengan integritas penuh, memberikan laporan <span className={Style["italic"]}>bug</span>
                            dengan niat baik, dan tidak mengeksploitasi celah untuk keuntungan pribadi.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Keputusan akhir mengenai pelanggaran terhadap syarat dan ketentuan ini berada di tangan tim
                            Starcade dan tidak dapat diganggu gugat.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Periode Bug Hunter Season 1 Berakhir pada 31 Januari 2024.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Tim QA Starcade akan melakukan validasi laporan dimulai setelah periode Bug Hunter Season 1
                            berakhir.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Pemenang Bug Hunter akan diumumkan secara resmi pada tanggal 19 Februari 2024 di saluran
                            media sosial Starcade:
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            Instagram: <span className={Style["yellow"]}
                                             onClick={onClickInstagram}>@starcadegame_id</span>
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            Facebook Group: <span className={Style["yellow"]} onClick={onClickFBGroup}>Starcade Digital Arcade</span>
                        </p>
                    </div>
                    <div className={joinClassNames(Style["item"], Style["sub"])}>
                        <img src={CheckCircleIcon} alt="check"/>
                        <p className={Style["text"]}>
                            Facebook Fanpage: <span className={Style["yellow"]} onClick={onClickFBPage}>Starcade Digital Arcade</span>
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Pemberitahuan kepada Pemenang: Pemenang akan diinformasikan melalui Mailbox di In-Game dan
                            perlu mengisi formulir untuk mengonfirmasi detail mereka untuk distribusi hadiah.
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={DiamondIcon} alt="diamond"/>
                        <p className={Style["text"]}>
                            Distribusi Hadiah: Hadiah dikirim melalui metode pembayaran atau platform pilihan yang
                            disediakan oleh pengguna (contoh: Gopay).
                        </p>
                    </div>
                    <div className={Style["item"]}>
                        <img src={WarningYellowIcon} alt="warning"/>
                        <p className={Style["text"]}>
                            Dengan mengikuti Bug Hunter, peserta dianggap telah membaca, memahami, dan menyetujui semua
                            syarat dan ketentuan yang tercantum di atas. Peserta yang melanggar syarat dan ketentuan ini
                            dapat dikenai sanksi, termasuk tetapi tidak terbatas pada diskualifikasi dari Bug Hunter dan
                            pembekuan akun ataupun laporan hukum.
                        </p>
                    </div>
                    <p className={Style["description"]}>
                    Tetap pantau informasi terbaru dan ikuti kami di platform media sosial kami untuk mendapatkan
                        pengumuman pemenang Bug Hunter Season 1!
                    </p>
                    <p className={Style["description"]}>
                        Terima kasih telah menjadi bagian dari Starcade iOS Beta Test! Bersama-sama, mari ciptakan
                        pengalaman bermain yang lebih baik.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default BugHunterTerms;
