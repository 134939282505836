import React from "react";
import Style from "./account-deletions.module.scss";

interface IAccountDeletionsProps {}

const AccountDeletions: React.FC<IAccountDeletionsProps> = () => {
  return (
    <div className={Style["account-deletions"]} id="account-deletions">
      <h1 className={Style["title"]}>Account Deletions</h1>
      <p className={Style["value"]}>
        You may request for your account to be deleted along with any related
        information by contacting us via email at{" "}
        <a href="mailto:support@starcade.co?subject=Account Deletion Request">
          support@starcade.co
        </a>
      </p>
      <p className={Style["value"]}>
        If contacting us via email, please use the subject "Account Deletion
        Request"
      </p>
    </div>
  );
};

export default AccountDeletions;
