import React from "react";
import Style from "./explore.module.scss";

import CloudDownload from "assets/icons/cloud-download.png";
import GooglePlay from "assets/images/google-play.png";

import { Text } from "components/atoms/text";
import { Image } from "components/atoms/image";
import { Link } from "components/atoms/link";
import { Divider } from "components/atoms/divider";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import WishfulWonder from "assets/images/wishful-wonder.png";
import AstroCity from "assets/images/astro-city.png";
import WitchHunter from "assets/images/witch-hunter.png";
import CandyParty from "assets/images/candy-party.png";
import OceanShooter from "assets/images/ocean-shooter.png";
import GoldenDragon from "assets/images/golden-dragon.png";
import ChevronRight from "assets/icons/chevron-right.png";
import ChevronLeft from "assets/icons/chevron-left.png";

interface IExploreProps {}

interface IGameList {
  name: string;
  description: string;
  image: any;
}

interface IButtonGroupProps {
  next?: () => void;
  previous?: () => void;
  setAutoplay: (value: boolean) => void;
}

interface ICustomDotProps {
  setAutoplay: (value: boolean) => void;
  onClick?: () => void;
  active?: boolean;
}

const gameList: IGameList[] = [
  {
    name: "Wishful Wonder",
    description:
      "Immerse yourself in the magical world full of wonders. Let’s match the beads and tell your wish!",
    image: WishfulWonder,
  },
  {
    name: "Astro City",
    description:
      "A relaxing and marvelous game you can have in your pocket. Drop the balls of luck and be prepared for the surprises!",
    image: AstroCity,
  },
  {
    name: "Witch Hunter",
    description:
      "Show your might against the wicked witch and her minions. Drop the coins, earn more points!",
    image: WitchHunter,
  },
  {
    name: "Candy Party",
    description:
      "In the land of sweet and fruity, with the magical grace of Sweet Fairy, Release the fortunate ball and brace yourself for unforeseen delights.",
    image: CandyParty,
  },
  {
    name: "Ocean Shooter",
    description:
      "Submerge into the ultimate deep-sea shooter: Customize your ship and conquer the sea!",
    image: OceanShooter,
  },
  {
    name: "Golden Dragon",
    description:
      "Bring the ultimate arcade experience in your hand every time and every where. Let’s tap, drop, and win!",
    image: GoldenDragon,
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 2,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    partialVisibilityGutter: 500,
  },
  tabletAndMobile: {
    breakpoint: { max: 1024, min: 0 },
    items: 1,
  },
};

const ButtonGroup: React.FC<IButtonGroupProps> = ({
  next,
  previous,
  setAutoplay,
}) => {
  const handleClickNext = () => {
    setAutoplay(false);
    if (next) {
      next();
    }
  };

  const handleClickPrevious = () => {
    setAutoplay(false);
    if (previous) {
      previous();
    }
  };

  return (
    <div className={Style["button-group"]}>
      <div className={Style["button"]} onClick={handleClickPrevious}>
        <Image
          src={ChevronLeft}
          alt="Previous"
          joinClassName={Style["chevron-icon"]}
        />
      </div>
      <div className={Style["button"]} onClick={handleClickNext}>
        <Image
          src={ChevronRight}
          alt="Next"
          joinClassName={Style["chevron-icon"]}
        />
      </div>
    </div>
  );
};

const CustomDot: React.FC<ICustomDotProps> = ({
  onClick,
  active,
  setAutoplay,
}) => {
  const handleClick = () => {
    setAutoplay(false);
    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      className={active ? Style["dot-active"] : Style["dot-inactive"]}
      onClick={handleClick}
    />
  );
};

const Explore: React.FC<IExploreProps> = () => {
  const items = React.useMemo(
    () =>
      gameList.map((game, index) => (
        <div className={Style["game-box"]} key={`game-item-${index + 1}`}>
          <Image
            src={game.image}
            alt={game.name}
            className={Style["game-image"]}
          />
          <div className={Style["game-info"]}>
            <Text className={Style["game-name"]}>{game.name}</Text>
            <Divider
              joinClassName={Style["divider"]}
              type="horizontal"
              color="rgba(226, 216, 226, 0.50)"
              size={2}
            />
            <Text className={Style["game-description"]}>
              {game.description}
            </Text>
          </div>
        </div>
      )),
    []
  );

  const [isAutoPlay, setIsAutoPlay] = React.useState(true);

  return (
    <div className={Style["explore"]} id="games">
      <Text className={Style["title"]}>
        Explore Our Diverse Game Collection!
      </Text>
      <div className={Style["download-box"]}>
        <div className={Style["left"]}>
          <div className={Style["icon-circle"]}>
            <Image
              src={CloudDownload}
              alt="Download"
              className={Style["image"]}
            />
            <Image
              src={CloudDownload}
              alt="Download"
              className={Style["image-blur"]}
            />
          </div>
          <Text className={Style["download-text"]}>
            Download Starcade from Google Play and set out on your voyage.
          </Text>
        </div>
        <div className={Style["right"]}>
          <Link
            href="https://play.google.com/store/apps/details?id=com.starcade.digitalarcade"
            target="_blank"
          >
            <Image
              className={Style["download-image"]}
              src={GooglePlay}
              alt="Google Play"
            />
          </Link>
        </div>
      </div>
      <div className={Style["carousel"]}>
        <Carousel
          responsive={responsive}
          showDots
          ssr={false}
          autoPlay={isAutoPlay}
          autoPlaySpeed={5000}
          arrows={false}
          infinite
          renderButtonGroupOutside
          renderDotsOutside
          customButtonGroup={<ButtonGroup setAutoplay={setIsAutoPlay} />}
          customDot={<CustomDot setAutoplay={setIsAutoPlay} />}
          shouldResetAutoplay
          partialVisible
          draggable={false}
        >
          {items}
        </Carousel>
      </div>
    </div>
  );
};

export default Explore;
