import classNames from 'classnames';
import React, { useMemo } from 'react';
import Style from './button.module.scss';

type TButtonNativeProps = React.DetailedHTMLProps<
	React.ButtonHTMLAttributes<HTMLButtonElement>,
	HTMLButtonElement
>;

interface IButtonProps extends TButtonNativeProps {
	variant?: 'primary' | 'secondary' | 'tertiary' | 'primary-round';
	parallelogram?: boolean;
	block?: boolean;
	joinClassName?: string;
	childrenClassName?: string;
}

const Button: React.FC<IButtonProps> = (props) => {
	const {
		children,
		variant,
		parallelogram,
		block,
		className,
		joinClassName,
		childrenClassName,
		...otherProps
	} = props;

	const cn = useMemo(() => {
		if (className) {
			return className;
		}

		let result = Style[`button-${variant}`];

		if (joinClassName) {
			result = classNames(result, joinClassName);
		}

		if (block) {
			const tempBlock = parallelogram ? 'block-parallelogram' : 'block';
			result = classNames(result, Style[tempBlock]);
		}

		if (parallelogram) {
			result = classNames(result, Style['parallelogram']);
		}

		return result;
	}, [joinClassName, className, variant, block, parallelogram]);

	const childrenCn = useMemo(() => {
		let result = Style[`children-wrapper`];

		if (childrenClassName) {
			result = classNames(result, childrenClassName);
		}

		if (parallelogram) {
			result = classNames(result, Style['fix-parallelogram']);
		}

		if (variant === 'primary-round') {
			result = classNames(result, Style['fix-primary-round']);
		}

		return result;
	}, [childrenClassName, parallelogram]);

	return (
		<button className={cn} {...otherProps} data-testid='button'>
			<div className={childrenCn} data-testid='children-wrapper'>
				{children}
			</div>
		</button>
	);
};

Button.defaultProps = {
	variant: 'primary',
	parallelogram: true,
	block: false,
	type: 'button',
};

export default Button;
