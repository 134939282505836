import React from "react";
import Style from "./beta-description.module.scss";

import TestFlightImage from "assets/images/testflight.png";
import DiamondIcon from "assets/icons/diamond.png";
import {Text} from "../../../../components/atoms/text";
import {Image} from "../../../../components/atoms/image";
import ArrowRight from "../../../../assets/icons/arrow-right.png";
import {Button} from "../../../../components/atoms/button";
import {joinClassNames} from "../../../../utils/utils";

interface IBetaDescriptionProps {}

const BetaDescription: React.FC<IBetaDescriptionProps> = () => {

    const onClickJoinBetaTest = React.useCallback(() => {
        window.open("https://testflight.apple.com/join/uFPE1YwM");
    }, []);

    return (
        <div className={Style["beta-description"]} id="beta-description">
            <p className={Style["title"]}>Starcade iOS Beta Test!</p>
            <div className={Style["description-item"]}>
                <div className={Style["left-item-1"]}>
                    <img alt={"testflight"} src={TestFlightImage}/>
                </div>
                <div className={Style["text-content"]}>
                    <p className={Style["content-title"]}>Langkah 1: Unduh TestFlight</p>
                    <p className={Style["content-description"]}>
                        Unduh TestFlight dari App Store untuk iPhone, iPad, Mac, Apple TV, Watch, dan iMessage. Apple
                        Vision Pro sudah memiliki TestFlight terpasang.
                    </p>
                </div>
            </div>
            <div className={Style["description-item"]}>
                <div className={Style["left-item-2"]}>
                    <div className={Style["img"]}>
                        <div className={Style["beta-info"]}>
                            <p className={Style["text"]}>
                                BETA
                            </p>
                        </div>
                    </div>
                </div>
                <div className={joinClassNames(Style["text-content"], Style["content-2"])}>
                    <p className={Style["content-title"]}>Langkah 2: Gabung di Beta Test</p>
                    <div className={Style["content-item"]}>
                        <img alt={"diamond"} src={DiamondIcon} className={Style["icon"]}/>
                        <p className={Style["text"]}>
                            Klik tombol di bawah ini untuk diarahkan ke Halaman Uji Coba Beta Starcade.
                        </p>
                    </div>
                    <div className={Style["content-item"]}>
                        <img alt={"diamond"} src={DiamondIcon} className={Style["icon"]}/>
                        <p className={Style["text"]}>
                            Klik "Accept" dan kemudian "Install" untuk memulai proses pengunduhan dan instalasi.
                        </p>
                    </div>
                </div>
            </div>
            <Button
                joinClassName={Style["button-container"]}
                childrenClassName={Style["button"]}
                parallelogram={false}
                variant="primary-round"
                onClick={onClickJoinBetaTest}
            >
                <Text className={Style["button-text"]}>Ikuti Beta Test Disini</Text>
                <Image
                    src={ArrowRight}
                    className={Style["button-icon"]}
                    alt="arrow-right-icon"
                />
            </Button>
        </div>
    );
};

export default BetaDescription;
