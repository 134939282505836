import React from "react";
import Style from "./terms-conditions.module.scss";
import { AccountDeletions } from "./components/account-deletions";

interface ITermsConditionsProps {}

const TermsConditions: React.FC<ITermsConditionsProps> = () => {
  return (
    <div className={Style["terms-conditions"]}>
      <AccountDeletions />
    </div>
  );
};

export default TermsConditions;
