import React from "react";
import Navbar from "components/organisms/navbar";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "components/organisms/footer";

const appStyle = {
  backgroundColor: "#0e022a",
};

export default function Root(): JSX.Element {
  return (
    <Grid style={appStyle}>
      <Navbar />
      <Outlet />
      <Footer />
    </Grid>
  );
}
