import React from "react";
import Style from "./footer.module.scss";

import Logo from "assets/images/starcade-logo.png";
import Instagram from "assets/icons/instagram.png";
import LinkedIn from "assets/icons/linkedin.png";
import Facebook from "assets/icons/facebook.png";
// import Twitter from "assets/icons/twitter.png";

import { Text } from "components/atoms/text";
import { Divider } from "components/atoms/divider";
import { Image } from "components/atoms/image";
import { Link } from "components/atoms/link";

interface IFooterProps {}

const Footer: React.FC<IFooterProps> = () => {
  return (
    <div className={Style["footer"]}>
      <div className={Style["top-section"]}>
        <div className={Style["left-section"]}>
          <Image className={Style["logo"]} src={Logo} alt="Starcade Logo" />
        </div>
        <div className={Style["right-section"]}>
          <div className={Style["left"]}>
            <Link href="mailto:hello@starcade.co" target="_blank">
              <Text className={Style["text"]}>hello@starcade.co</Text>
            </Link>
          </div>
          <Divider
            type="vertical"
            color="#3A3650"
            joinClassName={Style["divider"]}
          />
          <div className={Style["right"]}>
            <Link
              href="https://www.instagram.com/starcadegame_id/"
              target="_blank"
              className={Style["link-icon"]}
            >
              <Image
                className={Style["icon"]}
                src={Instagram}
                alt="Instagram Icon"
              />
            </Link>
            <Link
              href="https://www.linkedin.com/company/starcadegame"
              target="_blank"
              className={Style["link-icon"]}
            >
              <Image
                className={Style["icon"]}
                src={LinkedIn}
                alt="LinkedIn Icon"
              />
            </Link>
            <Link
              href="https://www.facebook.com/profile.php?id=61550932751720"
              target="_blank"
              className={Style["link-icon"]}
            >
              <Image
                className={Style["icon"]}
                src={Facebook}
                alt="Facebook Icon"
              />
            </Link>
            {/* <Link href="#" target="_blank" className={Style["link-icon"]}>
              <Image
                className={Style["icon"]}
                src={Twitter}
                alt="Twitter Icon"
              />
            </Link> */}
          </div>
        </div>
      </div>
      <Divider type="horizontal" color="#3A3650" />
      <div className={Style["bottom-section"]}>
        <Text className={Style["text-starcade"]}>
          © Starcade Pte. Ltd. 2023
        </Text>
        <Link href="/terms-conditions" target="_blank">
          <Text className={Style["text-terms"]}>Term and Conditions</Text>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
