export const handleScrollToSection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
	if (!e.currentTarget.href) return;

	// prevent default behaviour
	e.preventDefault();

	// if the href is an external link, if the href not contains a hash (#), and it's not a link to the current page
	// then open it in a new tab
	if (!e.currentTarget.href.includes('#') && e.currentTarget.href !== window.location.href) {
		window.open(e.currentTarget.href, '_blank');
		return;
	}

	if (e.currentTarget.href !== window.location.href) {
		window.open(e.currentTarget.href, '_self');
	}

	// get the href and remove everything before the hash (#)
	const href = e.currentTarget.href;
	const targetId = href.replace(/.*#/, '');

	// get the element by id and use scrollIntoView
	const elem = document.getElementById(targetId);
	elem?.scrollIntoView({
		behavior: 'smooth',
	});
};

export const numberFormatter = (num: number, currency?: string) => {
	const newFormat = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	if (currency) {
		return `${currency} ${newFormat}`;
	}
	return newFormat;
}

export const joinClassNames = (...classNames: string[]) => {
	return classNames.join(' ');
}
