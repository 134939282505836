import React from "react";
import Style from "./home.module.scss";

import { BannerSection } from "./components/banner";
import { ArcadeInfoSection } from "./components/arcade-info";
import { ExploreSection } from "./components/explore";
import { ContactUsSection } from "./components/contact-us";

interface IHomeProps {}

const Home: React.FC<IHomeProps> = () => {
  return (
    <div className={Style["home"]}>
      <BannerSection />
      <ArcadeInfoSection />
      <ExploreSection />
      <ContactUsSection />
    </div>
  );
};

export default Home;
