import classNames from "classnames";
import React, { useMemo } from "react";
import Style from "./link.module.scss";

interface ILinkProps {
  joinClassName?: string;
  href?: string;
  children?: React.ReactNode;
  className?: string;
  target?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  title?: string;
}

const Link: React.FC<ILinkProps> = (props) => {
  const { children, href, className, joinClassName, ...otherProps } = props;

  const cn = useMemo(() => {
    if (joinClassName) {
      return classNames(Style["link"], joinClassName);
    }

    if (className) {
      return className;
    }

    return Style["link"];
  }, [joinClassName, className]);

  const linkHref = useMemo(() => {
    if (!href) {
      return "#";
    }

    return href;
  }, [href]);

  return (
    <a href={linkHref} className={cn} {...otherProps} data-testid="link">
      {children}
    </a>
  );
};

export default Link;
