import React from "react";
import Style from "./how-to-report.module.scss";

import Step1Image from "assets/images/step-1.png";
import Step2Image from "assets/images/step-2.png";
import Step3Image from "assets/images/step-3.png";
import Step4Image from "assets/images/step-4.png";

import { Image } from "components/atoms/image";

interface IHowToReportProps {}

const HowToReport: React.FC<IHowToReportProps> = () => {
    return (
        <div className={Style["how-to-report"]} id="how-to-report">
            <p className={Style["title"]}>Cara Membuat Laporan Bug</p>
            <div className={Style["content"]}>
                <div className={Style["item"]}>
                    <div className={Style["stepper"]}>
                        <p className={Style["text"]}>Step 1</p>
                    </div>
                    <Image src={Step1Image} alt="Step 1" />
                    <p className={Style["text"]}>Buka Aplikasi TestFlight dan Buka Starcade: Digital Arcade</p>
                </div>
                <div className={Style["item"]}>
                    <div className={Style["stepper"]}>
                        <p className={Style["text"]}>Step 2</p>
                    </div>
                    <Image src={Step2Image} alt="Step 2" />
                    <p className={Style["text"]}>Klik Send Beta Feedback</p>
                </div>
                <div className={Style["item"]}>
                    <div className={Style["stepper"]}>
                        <p className={Style["text"]}>Step 3</p>
                    </div>
                    <Image src={Step3Image} alt="Step 3" />
                    <p className={Style["text"]}>Pilih “Include Screenshot” / “Sertakan Tangkapan Layar”</p>
                </div>
                <div className={Style["item"]}>
                    <div className={Style["stepper"]}>
                        <p className={Style["text"]}>Step 4</p>
                    </div>
                    <Image src={Step4Image} alt="Step 4" />
                    <p className={Style["text"]}>WAJIB menuliskan emailmu dan tuliskan masalah yang kamu temukan dan Klik Submit!</p>
                </div>
            </div>
        </div>
    );
};

export default HowToReport;