import classNames from 'classnames';
import React, { useMemo } from 'react';
import Style from './divider.module.scss';

type TDivProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

type TDividerType = 'vertical' | 'horizontal';

interface IDividerProps extends TDivProps {
	type: TDividerType;
	size?: string | number;
	spaceBetween?: string | number;
	color?: string;
	joinClassName?: string;
}

const Divider: React.FC<IDividerProps> = (props) => {
	const {
		className,
		joinClassName,
		type,
		size = 1,
		color = 'black',
		spaceBetween = 0,
		...otherProps
	} = props;

	const cn = useMemo(() => {
		if (joinClassName) {
			return classNames(Style['divider'], joinClassName);
		}

		if (className) {
			return className;
		}

		return Style['divider'];
	}, [joinClassName, className]);

	const style = useMemo(() => {
		if (type === 'vertical') {
			return {
				width: size,
				height: 'auto',
				display: 'inline-block',
				marginLeft: spaceBetween,
				marginRight: spaceBetween,
			};
		}

		return {
			width: '100%',
			height: size,
			marginTop: spaceBetween,
			marginBottom: spaceBetween,
		};
	}, [type, size, spaceBetween]);

	return (
		<div
			className={cn}
			style={{
				backgroundColor: color,
				...style,
			}}
			{...otherProps}
			data-testid='divider'
		/>
	);
};

export default Divider;
