import classNames from 'classnames';
import React, { CSSProperties, useMemo } from 'react';
import Style from './text.module.scss';

type TParaghraphProps = React.DetailedHTMLProps<
	React.HTMLAttributes<HTMLParagraphElement>,
	HTMLParagraphElement
>;

interface ITextProps extends TParaghraphProps {
	variant?: 'light' | 'dark';
	fontStyle?: CSSProperties['fontStyle'];
	fontSize?: CSSProperties['fontSize'];
	fontWeight?: CSSProperties['fontWeight'];
	color?: CSSProperties['color'];
	joinClassName?: string;
}

const Text: React.FC<ITextProps> = (props) => {
	const {
		children,
		variant,
		fontStyle,
		fontSize,
		fontWeight,
		color,
		joinClassName,
		className,
		style,
		...otherProps
	} = props;

	const cn = useMemo(() => {
		if (className) {
			return className;
		}

		let result = Style['text'];

		if (joinClassName) {
			result = classNames(result, joinClassName);
		}

		if (variant) {
			result = classNames(result, Style[variant]);
		}

		return result;
	}, [joinClassName, className]);

	return (
		<p
			className={cn}
			style={{
				fontStyle,
				fontSize,
				fontWeight,
				color,
				...style,
			}}
			{...otherProps}
			data-testid='text'
		>
			{children}
		</p>
	);
};

Text.defaultProps = {
	variant: 'light',
	fontStyle: 'normal',
	fontWeight: '400',
};

export default Text;
