import React from "react";
import Style from "./navbar.module.scss";

import {Image} from "components/atoms/image";
import Logo from "assets/images/starcade-logo.png";
import HamburgerIcon from "assets/icons/hamburger.png";
import CloseIcon from "assets/icons/close.png";

import {Link} from "components/atoms/link";
import {handleScrollToSection, joinClassNames} from "utils/utils";

interface NavbarProps {
}

interface INavbarItem {
    name: string;
    href: string;
}

const navbarItem: INavbarItem[] = [
    {
        name: "About",
        href: "/#about",
    },
    {
        name: "Games",
        href: "/#games",
    },
    {
        name: "Store",
        href: "https://store.starcade.co/",
    },
    {
        name: "Contact Us",
        href: "/#contact-us",
    },
    {
        name: "iOS Beta Test",
        href: "/iosbetatest",
    },
];

const Navbar: React.FC<NavbarProps> = () => {
    const [isNavbarOpen, setIsNavbarOpen] = React.useState(false);
    return (
        <React.Fragment>
            <div className={Style["navbar"]}>
                <div className={Style["left-side"]}>
                    <Link className={Style["logo-link"]} href="/">
                        <Image alt="logo" src={Logo} className={Style["logo"]}/>
                    </Link>
                    <div className={Style["navbar-item-container"]}>
                        {navbarItem.map((item, index) => {
                            const isActive = window.location.pathname === item.href;
                            return (
                                <div className={Style["link-container"]}>
                                    <Link
                                        title={item.name}
                                        href={item.href}
                                        key={index}
                                        className={joinClassNames(Style["link"], isActive ? Style["active"] : "")}
                                        onClick={handleScrollToSection}
                                    >
                                        {item.name}
                                    </Link>
                                    {isActive && <div className={Style["active-indicator"]}/>}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className={Style["right-side"]}>
                    <div
                        className={Style["hamburger-icon-link"]}
                        onClick={() => {
                            setIsNavbarOpen(true);
                        }}
                    >
                        <Image
                            alt="hamburger-icon"
                            src={HamburgerIcon}
                            className={Style["hamburger-icon"]}
                        />
                    </div>
                </div>
            </div>
            <div
                className={joinClassNames(
                    Style["navbar-sidemenu"],
                    isNavbarOpen ? Style["active"] : ""
                )}
            >
                <div
                    className={Style["close-icon"]}
                    onClick={() => {
                        setIsNavbarOpen(false);
                    }}
                >
                    <Image alt="close-icon" src={CloseIcon} className={Style["close"]}/>
                </div>

                {navbarItem.map((item, index) => (
                    <Link
                        title={item.name}
                        href={item.href}
                        key={index}
                        className={Style["link"]}
                        onClick={(e) => {
                            handleScrollToSection(e);
                            setIsNavbarOpen(false);
                        }}
                    >
                        {item.name}
                    </Link>
                ))}
            </div>
        </React.Fragment>
    );
};

export default Navbar;
