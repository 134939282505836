import React from "react";
import Style from "./ios-beta-test.module.scss";
import {ContactUsSection} from "./components/contact-us";
import {BetaDescriptionSection} from "./components/beta-description";
import {BugHunterDescriptionSection} from "./components/bug-hunter-description";
import {BugHunterTermsSection} from "./components/bug-hunter-terms";
import {HowToReport} from "./components/how-to-report";

interface IIOSBetaTestProps {}

const IOSBetaTest: React.FC<IIOSBetaTestProps> = () => {
    return (
        <div className={Style["ios-beta-test"]}>
            <BetaDescriptionSection />
            <BugHunterDescriptionSection />
            <HowToReport />
            <BugHunterTermsSection />
            <ContactUsSection />
        </div>
    );
};

export default IOSBetaTest;
