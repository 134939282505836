import React from "react";
import Style from "./arcade-info.module.scss";

import AboutPlay from "assets/images/about-play.png";
import AboutWin from "assets/images/about-win.png";
import AboutCollect from "assets/images/about-collect.png";

import { Text } from "components/atoms/text";
import { Image } from "components/atoms/image";

interface IArcadeInfoProps {}

const items = [
  {
    title: "Play",
    description:
      "Immerse yourself in captivating gameplay, dive into endless adventures.",
    image: AboutPlay,
  },
  {
    title: "Win",
    description:
      "Compete, conquer, and emerge as the ultimate champion to claim your well-deserved rewards in Starcade.",
    image: AboutWin,
  },
  {
    title: "Collect",
    description:
      "Collect treasures, unique pets, and valuable items in your journey through Starcade's arcade wonders.",
    image: AboutCollect,
  },
];

const ArcadeInfo: React.FC<IArcadeInfoProps> = ({}) => {
  return (
    <div className={Style["arcade-info"]} id="about">
      <Text className={Style["title"]}>Your Digital Arcade Game</Text>
      <div className={Style["info"]}>
        {items.map((item, index) => (
          <div className={Style["box"]} key={`box-items-${index + 1}`}>
            <div className={Style["circle"]} />
            <Image
              src={item.image}
              className={Style["image"]}
              alt="Image Arcade"
            />
            <Text className={Style["title"]}>{item.title}</Text>
            <Text className={Style["description"]}>{item.description}</Text>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ArcadeInfo;
